export default function About() {
    return (
        <div className="flex justify-center pb-40 items-center mx-auto" style={{height: "90vh"}}>
            <div className="p px-2 py-80 text-lg relative text-center mx-auto" style={{wordBreak: "keep-all", whiteSpace: "pre-line"}}>
                <div className="py-80">
                    {`우리는 누구나 자유롭게 음악할 수 있는 세상을 만들고, 커뮤니티를 형성할 수 있도록 돕습니다.`}
                </div>
                <div className="p text-sm" style={{wordBreak: "keep-all", whiteSpace: "pre-line"}}>
                    {`문의사항은 아래 이메일로 받고 있습니다.
                    감사합니다.
                    gin@www.transient.kr`}
                </div>
            </div>
        </div>
    )
}