import WorkItem from "../component/WorkItem";
import Study from "../component/study.jpeg";
import Hendrix1 from "../component/hendrix1.jpg";
import Hendrix2 from "../component/hendrix2.jpg";
import House from "../component/house.jpg";
import Cat from "../component/laptop.jpeg";

const Albums = [
    { title: "Trauma", description: "안녕 내 트라우마", thumbImg: Study },
    { title: "Castles Made of Sand", description: "Hednrix da best", thumbImg: Hendrix1 },
    { title: "Da Funk", description: "하나뿐인", thumbImg: Hendrix2 },
    { title: "Ma Home", description: "Christiania", thumbImg: House },
    { title: "Meow", description: "Laptop", thumbImg: Cat },
]

export default function Works() {

    return (
        <div className="grid grid-cols-4 gap-8">
            { Albums.map(( val, idx ) => (
                <WorkItem key={idx} item={val} />
            ))}
        </div>
    )
}