import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const imgStyle = {
    maxHeight: "130px",
    width: "100%",
}
export default function BlogThumb({ item }) {
    const navigate = useNavigate();

    const onClickThumb = useCallback(() => {
        navigate(`/blog/${item.$id}`,
            {
                state: {
                    item: item,
                }
            }
        )
    }, [ item, navigate ]);

    return (
        <div className="w-full max-40 border-2 border-black hover:bg-gray-100 cursor-pointer rounded-lg py-5 pt-3 px-5" onClick={onClickThumb}>
            <img className="my-3 object-cover" src={item.$thumbnail} alt={item.$thumbnail} style={imgStyle} />
            <div className="p text-sm">{item.$subtitle}</div>
            <div className="p text-lg font-semibold whitespace-pre-wrap break-words">{item.$title}</div>
            <div className="h5 text-gray-700 text-xs mt-2">{item.$timestamp}</div>
        </div>
    )
}