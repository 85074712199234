import { LooksOneRounded, LooksTwoRounded, Looks3Rounded, ArrowDownward } from '@mui/icons-material';

import { orange } from '@mui/material/colors';

import AppLauncher from '../component/AppLauncher';

import ContestImg1 from '../images/contest1.png';
import ContestImg2 from '../images/contest2.png';
import ContestImg3 from '../images/contest3.png';
import SynthyLogo from '../images/synthy-logo-01.png';
import { Fragment } from 'react';

const LogoStyle = {
    width: "200px",
    height: "auto",
    aspectRatio: "auto",
}
const ImgStyle = {
    width: "100%"
}

const ArrowStyle = {
    width: "max(40px, 7vh)",
    height: "max(40px, 7vh)",
    margin: "0 auto",
    textAlign: "center",
}

export default function ContestInfo() {

    return (
        <Fragment>
            <section>
            <div className="flex flex-col max-w-6xl mx-auto px-6 h-fit mb-20">
                <div className='relative flex flex-col max-w-full h-[80vh] items-center justify-center'>
                    <img className='mb-4' style={LogoStyle} src={SynthyLogo} />
                    <div className="h1">Music Contest</div>
                    <div className="absolute bottom-2 items-center justify-center text-center">
                        <ArrowDownward style={ArrowStyle}/>
                        <div className="p text-center">스크롤하여<br/>더보기</div>
                    </div>
                </div>
                <div className="flex flex-col h-screen items-center justify-center">
                    <div className='p text-xl font-semibold text-center' style={{wordBreak: "keep-all"}}>구구톡 내의 SYNTHY에 회원가입한 후 아래의 참여 방법을 따라주세요!</div>
                    <div className='my-3'><AppLauncher /></div>
                </div>
                <div className='h-fit items-center justify-center my-2' style={{wordBreak: "keep-all"}}>
                    <div className="h3 text-center mb-12">참여 방법</div>
                    <div className="grid grid-rows-3 grid-cols-none md:grid-rows-none md:grid-cols-3 gap-10">
                        <div className='flex flex-col gap-2'>
                            <div className='p flex flex-row gap-2 text-md md:text-lg font-medium'>
                                <LooksOneRounded className='m-1' sx={{ color: orange[800] }}/> 메인화면에서 컨테스트 탭을<br/>클릭해주세요.</div>
                            <img className='max-w-full mx-auto md:max-w-none h-auto'
                                style={ImgStyle}
                                src={ContestImg1}
                                alt="Contest Info" />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='p flex flex-row gap-2 text-md md:text-lg font-medium'>
                                <LooksTwoRounded className='m-1' sx={{ color: orange[800] }}/> 화면 하단에 뜨는 ‘+’ 버튼을<br/>클릭해주세요.</div>
                            <img className='max-w-full mx-auto md:max-w-none h-auto'
                                style={ImgStyle}
                                src={ContestImg2}
                                alt="Contest Info" />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='p flex flex-row gap-2 text-md md:text-lg font-medium'>
                                <Looks3Rounded className='m-1' sx={{ color: orange[800] }}/> 아래 양식을 채운 뒤 ‘올리기' 버튼을 누르면 컨테스트 참여가 완료됩니다.</div>
                            <img className='max-w-full mx-auto md:max-w-none h-auto'
                                style={ImgStyle}
                                src={ContestImg3}
                                alt="Contest Info" />
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </Fragment>
    )
}