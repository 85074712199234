import { Avatar } from "@mui/material";
import { useState } from "react";
import Services from "./menus/Services";
import Works from "./menus/Works";
import Tools from "./menus/Tools";
import Info from "./menus/Info";

const Menus = [
    {
        id: 1,
        label: "서비스",
    },
    {
        id: 2,
        label: "작업",
    },
    {
        id: 3,
        label: "장비",
    },
    {
        id: 4,
        label: "정보",
    }
]

export default function PersonalPortfolio() {
    const [ selectedMenu, setSelectedMenu ] = useState(1);

    return (
        <section>
            <div className="relative max-w-6xl mx-auto px-6 mt-20 items-center">
                <Avatar className="mx-auto" sx={{ width: 100, height: 100 }}></Avatar>
                <div className="h4 mx-auto my-4 text-center">hongju</div>
                <div className="flex mx-auto my-10 gap-10 justify-center">
                    { Menus.map((val, id) => (
                        val.id === selectedMenu ?
                        <div className="p px-3 py-1 cursor-pointer bg-black text-gray-200 rounded-2xl">{val.label}</div> :
                        <div className="p px-3 py-1 cursor-pointer" onClick={() => setSelectedMenu(val.id)}>{val.label}</div>
                    )) }
                </div>
                <div className="w-full h-fit py-8">
                    { selectedMenu === 1 ? <Services /> :
                    selectedMenu === 2 ? <Works /> :
                    selectedMenu === 3 ? <Tools /> :
                    <Info /> }
                </div>
            </div>
        </section>
    )
}