import SynthyLogo from '../images/synthy-logo-01.png';
import { OpenInNew } from '@mui/icons-material';
import axios from 'axios';
import { useCallback } from 'react';
import {
  AppStoreButton,
  GooglePlayButton,
} from "react-mobile-app-button";

const IconStyle = {
  width: "max(30px, 3vw)",
  height: "max(30px, 3vw)",
}

export default function Hero() {

  const send = useCallback(() => {
    axios.get('http://3.34.188.55/api/by-link', {
      mode: "youtube",
      url: "https://www.youtube.com/watch?v=PgkC6N7ZEpY",
      remove: "extract",
      stem: "bass",
    }).then((res) => {
      console.log(res);
    })
  }, [])

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">

        {/* Hero content */}
        <div className="relative pt-32 pb-10 md:pt-60 md:pb-80">
          <img className="max-w-full mx-auto md:max-w-none h-auto" src={SynthyLogo} width={1024}
            alt="Synthy Logo" />
          
          {/* Section header */}
          <div className="flex-col justify-center items-center md:max-w-3xl sm:max-w-none mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-6 md:mb-12  mt-6 md:mt-8 w-full" data-aos="fade-down">REALIZE YOUR IDEA!</h1>
            <div className="mx-auto mt-20 justify-center h-35 items-center grid md:grid-cols-3 md:grid-rows-none grid-cols-none grid-rows-3 gap-3 w-fit" data-aos="fade-down" data-aos-delay="400">
              {/* <div data-aos="fade-down" data-aos-delay="400">
                <a className="btn text-white bg-orange-600 hover:bg-orange-700 w-full mb-4 sm:w-auto sm:mb-0" href="https://apps.apple.com/kr/app/%EB%B9%84%EC%A6%88%ED%94%8C%EB%9E%AB-%EA%B5%AC%EA%B5%AC%ED%86%A1/id1576150335#?platform=iphone">오픈베타 사전등록</a>
              </div> */}
              <AppStoreButton
                url={"https://apps.apple.com/kr/app/%EB%B9%84%EC%A6%88%ED%94%8C%EB%9E%AB-%EA%B5%AC%EA%B5%AC%ED%86%A1/id1576150335"}
                theme={"dark"}
                height={70}
                width={200}
              />
              <GooglePlayButton
                url={"https://play.google.com/store/apps/details?id=com.dinosband.gugutalk.client&pcampaignid=web_share"}
                theme={"dark"}
                height={70}
                width={200}
              />
              <div className="btn cursor-pointer h-full w-full p-0 m-0 border-black hover:bg-gray-200"
                onClick={send}>
              {/* onClick={() => window.open("https://webapp.99talk.app/")}> */}
                <div className="flex items-center gap-2">
                  <div className="p text-lg">또는 웹앱 열기</div><OpenInNew sx={IconStyle} />
                </div>
              </div>
            </div>
            <div className='md:flex gap-0 md:gap-1 w-fit justify-center items-center text-center mx-auto my-2' data-aos="fade-down" data-aos-delay="600">
              <div className='p text-xs md:text-sm text-center md:text-end'>{`[비즈플랫-구구톡 설치 → 회원가입 → 왼쪽 상단 메뉴`}</div>
              <div className='p text-xs md:text-sm text-center md:text-start'>{`→ SYNTHY 선택 → SYNTHY 가입]`}</div>
            </div>
          </div>


        </div>
      </div>
    </section>
  )
}
