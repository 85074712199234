import BoxItem from "./BoxItem"

export default function WorkItem({ item }) {
    return (
        <BoxItem
            thumbImg={item.thumbImg}
            title={item.title}>
            <div className="p">{item.description}</div>
        </BoxItem>
    )
}