import { useState } from 'react';
import SynthyLogo from '../images/synthy-logo-01.png';

export default function Partners() {
    const [password, setPassword] = useState('');

    if (password !== "synthy2024"){
        return (
            <div className="flex justify-center items-center mx-auto" style={{height: "80vh"}}>
                <input className="relative text-center mx-auto bg-gray-100 rounded-xl" style={{heiht: "40px", width: "300px"}} onChange={(e) => setPassword(e.target.value)} placeholder='미리 안내드린 비밀번호를 입력하세요.'/>
            </div>
        )
    }
    return (
        <section>
        <div className="relative max-w-6xl mx-auto px-6 mt-20">
            {/* Hero content */}
            <div className="relative pt-20">
            <img className="max-w-full mx-auto md:max-w-none h-auto mb-4 mt-6" src={SynthyLogo} width={512} 
                // style={{ filter: 'invert(100%)' }}
                alt="Synthy Logo" />
                <h1 className="h1 mb-12 mt-11 w-full text-center">PARTNERS</h1>
            
            {/* Section header */}
                <div className="md:max-w-3xl mx-auto text-center mt-12 mb-20 md:pb-20">
                    {/* <p className="text-xl text-gray-600 mb-8" data-aos="fade-down" data-aos-delay="200">Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.</p> */}
                    <div className="w-full mx-auto">
                    <div className="p text-black" style={{wordBreak: "keep-all", whiteSpace: "pre-line"}}>
                    {`안녕하세요. 음악인 매칭 플랫폼 <SYNTHY>입니다.
                    우선, <SYNTHY 파트너스>에 지원해주신 여러분께 감사의 말씀 전합니다.
                    
                    <SYNTHY 파트너스>는 <SYNTHY>의 주요 전문가로 활동하며 주로 '자료 찾기' 서비스에 등록될 자료를 제작하는 역할을 수행합니다. <SYNTHY> 초기 구매자 분들께 최상의 이용 경험을 제공하고자 <SYNTHY 파트너스> 여러분들이 제작해주신 자료의 상업적 퀄리티 향상을 지원할 예정입니다.
                    따라서 최소한의 검증을 진행하고자 ‘Sample 자료’를 요청드립니다.

                    ‘Sample 자료’의 분야와 형식은 자유이며, 다음 <SYNTHY>의 ‘자료 찾기’ 카테고리를 참고하여 제작해주세요.
                    ‘Sample 자료’는 ‘예상 가격’과 함께 10월 31일까지`}</div>
                    <p className="font-semibold text-black">gin@www.transient.kr</p>
                    <div className="p text-black" style={{wordBreak: "keep-all", whiteSpace: "pre-line"}}>{`위 이메일로 제출해주시면 감사하겠습니다.`}</div>
                    <div className='p text-sm' style={{wordBreak: "keep-all", whiteSpace: "pre-line"}}>
                        {`\n메일 제목은 [ (SYNTHY닉네임)-Sample자료 ]로 형식에 맞춰주세요.\n예시) Gin-Sample자료\n\n\n
                        **‘자료 찾기’ 서비스는 음악 관련 학습을 위한 ‘학습 자료’와 음악 제작을 보조하는 ‘샘플/프리셋’으로 나누어져 있습니다.
                        **‘샘플/프리셋’은 [ 샘플팩 / 미디팩 / 플러그인 / 플러그인 프리셋 / DAW프로젝트 / 생상성 향상 ] 이렇게 6개의 카테고리가 포함됩니다.`}</div>
                    </div>
                </div>

                <div className="md:max-w-3xl sm:max-w-none mx-auto mt-20" style={{wordBreak: "keep-all", whiteSpace: "pre-line"}}>
                    <div className="p font-semibold text-md">SYNTHY 파트너스란?</div>
                    <div className="p text-sm">
                        {`<SYNTHY>의 주요 전문가로 활동하며 주로 ‘자료 찾기’ 서비스에 등록될 자료를 제작하는 역할을 수행합니다.
                        레슨, 전자책 등의 학습 자료, 샘플, 프리셋, DAW프로젝트 등의 다양한 디지털 자료를 제작할 수 있는 분을 찾습니다.
                        ** ‘자료 찾기’ 서비스는 디지털 자료를 등록하고 판매하여 수익을 창출할 수 있는 서비스입니다. **\n\n`}
                    </div>
                    <div className="p font-semibold text-md">SYNTHY 파트너스 혜택</div>
                    <div className='p text-sm'>
                        {`SYNTHY 정식 출시(2025년 1월 중 예정) 이후 ‘자료 찾기’에 등록하신 상품에 대한 프로모션을 진행해드립니다.
                        배너 광고 및 외부 광고 활용, 상품 상단 노출, 상품 구매 이벤트 등 다양한 구매자 확보 프로모션을 준비 중입니다.`}
                    </div>
                </div>
            </div>
        </div>
        </section>
    )
}