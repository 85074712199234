import { useCallback, useState } from "react";
import BlogThumb from "./BlogThumb";

import data from "./metadata.json";

export default function Blog() {
    const [ selected, setSelected ] = useState("All");

    const categories = [ "All", "Mix/Mastering", "History", "Etc." ];
  
    const TitleStyle = useCallback(( item ) => (
      `hover:text-gray-600 cursor-pointer ${selected.startsWith(item) && "text-gray-400"}`
    ), [ selected ]);
  
    const onClickMenu = useCallback(( item ) => {
        setSelected(item);
    }, [ setSelected ]);

    const MenuItems = useCallback(( { item } ) => (
        <p className={TitleStyle(item)} onClick={(() => onClickMenu(item))}>{item}</p>
    ), [ TitleStyle, onClickMenu ]);

    return (
        <section>
            <div className="max-w-6xl mx-auto px-6 mt-20 relative">
                <div className="h1 my-10">Blog</div>
                <div className="flex gap-5 md:gap-10 justify-start items-center list-none my-10">
                    {categories.map(( item, idx ) => (
                        <MenuItems key={idx} item={item} />
                    ))}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                    { data.map((val, idx) => (
                        ( selected === "All" || val.$group === selected ) &&
                        <BlogThumb key={idx} item={val} />
                    ))}
                </div>
            </div>
        </section>
    )
}