import AppLauncher from '../../component/AppLauncher';
import SynthyLogo from '../../images/synthy-logo-01.png';

export default function Hero() {

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">

        {/* Hero content */}
        <div className="relative pt-32 pb-10 md:pt-60 md:pb-80">
          <img className="max-w-full mx-auto md:max-w-none h-auto" src={SynthyLogo} width={1024}
            alt="Synthy Logo" />
          
          {/* Section header */}
          <div className="flex-col justify-center items-center md:max-w-3xl sm:max-w-none mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-6 md:mb-12  mt-6 md:mt-8 w-full" data-aos="fade-down">REALIZE YOUR IDEA!</h1>
            <div className="mt-20" data-aos="fade-down" data-aos-delay="400">
              <AppLauncher />
            </div>
          </div>


        </div>
      </div>
    </section>
  )
}
