import FeatImage01 from '../../images/zigzag1.png';
import FeatImage02 from '../../images/zigzag2.png';
import FeatImage03 from '../../images/zigzag3.png';

const IMG_WIDTH = 540;

export default function Zigzag() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6" style={{wordBreak: "keep-all"}}>
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>
            <h1 className="h2 mb-4">One product, unlimited solutions</h1>
            <p className="text-xl text-gray-600">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum — semper quis lectus nulla.</p>
          </div> */}

          {/* Items */}
          <div className="grid gap-20">

            {/* 3rd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage01} width={IMG_WIDTH} alt="Features 03" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-6 xl:pr-8">
                  <div className="font-architects-daughter text-lg text-orange-600 mb-3">음악 분야 프리랜서를 빠르게 만나보세요</div>
                  <h1 className="h1 mb-5">사람 찾기</h1>
                  <p className="text-xl mb-8">세분화된 분야, 서비스, 장르별 카테고리와 전문가의 포트폴리오를 통해 필요한 프리랜서를 간편하게 찾아보세요.</p>
                  <ul className="text-lg mb-2">
                    <li className="items-center mb-5">
                      <div className="flex items-center mb-1">
                        <div className="h4">전문가 찾기</div>
                        <svg className="w-3 h-3 fill-current text-green-500 ml-2 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                      </div>
                      <div className="text-sm text-gray-600" style={{fontSize:"15px"}}>원하는 분야의 전문가를 직접 검색하고 만나보세요.</div>
                    </li>
                    <li className="items-center mb-5">
                      <div className="flex items-center mb-1">
                        <div className="h4">작업 의뢰</div>
                        <svg className="w-3 h-3 fill-current text-green-500 ml-2 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                      </div>
                      <div className="text-sm text-gray-600" style={{fontSize:"15px"}}>작업 의뢰서를 작성하면 맞춤형 제안을 받을 수 있습니다.</div>
                    </li>
                    <li className="items-center mb-5">
                      <div className="flex items-center mb-1">
                        <div className="h4">공동 작업</div>
                        <svg className="w-3 h-3 fill-current text-green-500 ml-2 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                      </div>
                      <div className="text-sm text-gray-600" style={{fontSize:"15px"}}>음악적 아이디어를 공유할 협력자를 찾아보세요.</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center text-right align-end">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage02} width={IMG_WIDTH} alt="Features 02" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-6 xl:pr-8">
                  <div className="font-architects-daughter text-lg text-orange-600 mb-3">팀원 모집과 협업 기회를 확장하세요</div>
                  <h1 className="h1 mb-5">그룹 찾기</h1>
                  <p className="text-xl mb-8">팀을 결성하거나 새로운 팀원을 찾아 다양한 음악적 프로젝트를 함께하세요.</p>
                  <ul className="text-lg mb-2">
                    <li className="items-center mb-5">
                      <div className="flex items-center mb-1 justify-end">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <div className="h4">밴드 멤버 모집</div>
                      </div>
                      <div className="text-sm text-gray-600" style={{fontSize:"15px"}}>비슷한 음악적 취향을 가진 사람들과 밴드를 결성하세요.</div>
                    </li>
                    <li className="items-center mb-5">
                      <div className="flex items-center mb-1 justify-end">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <div className="h4">합동 공연</div>
                      </div>
                      <div className="text-sm text-gray-600" style={{fontSize:"15px"}}>대관료 부담 없이 다른 밴드와 함께 공연을 기획하세요.</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage03} width={IMG_WIDTH} alt="Features 01" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                <div className="md:pl-4 lg:pr-6 xl:pr-8">
                  <div className="font-architects-daughter text-lg text-orange-600 mb-3">음악 활동을 지원하는 다양한 자료를 확인하세요</div>
                  <h1 className="h1 mb-5">자료 찾기</h1>
                  <p className="text-xl mb-8">학습 자료부터 샘플팩까지, 음악적 성장을 위한 다양한 자료를 구매하고 공유하세요.</p>
                  <ul className="text-lg mb-2">
                    <li className="items-center mb-5">
                      <div className="flex items-center mb-1">
                        <div className="h4">학습 자료</div>
                        <svg className="w-3 h-3 fill-current text-green-500 ml-2 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                      </div>
                      <div className="text-sm text-gray-600" style={{fontSize:"15px"}}>음악 이론, 악기 레슨, DAW 사용법 등 실력을 키워줄 자료를 제공받으세요.</div>
                    </li>
                    <li className="items-center mb-5">
                      <div className="flex items-center mb-1">
                        <div className="h4">샘플/프리셋</div>
                        <svg className="w-3 h-3 fill-current text-green-500 ml-2 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                      </div>
                      <div className="text-sm text-gray-600" style={{fontSize:"15px"}}>다양한 장르의 샘플팩, 프리셋, 프로젝트 파일을 구입하고 작업에 활용하세요.</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
    </section>
  )
}
