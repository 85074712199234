import { Fragment } from 'react';

import Hero from './Hero';
import Features from './Features';
import Zigzag from './Zigzag';
import Newsletter from './Newsletter';
import Popup from '../../component/Popup';

export default function Home() {
    return (
        <Fragment>
            <Popup />
            <Hero />
            <Features />
            <Zigzag />
            <Newsletter />
        </Fragment>
    )
}