// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Switch element */
.form-switch {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    width: 60px;
}

.form-switch label {
    display: block;
    height: 2rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 9999px;
}

.form-switch label>span:first-child {
    position: absolute;
    display: block;
    border-radius: 9999px;
    width: 28px;
    height: 28px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all .15s ease-out;
}

.form-switch input[type="checkbox"]:checked+label {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.form-switch input[type="checkbox"]:checked+label>span:first-child {
    left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/css/additional-styles/toggle-switch.css"],"names":[],"mappings":"AAAA,mBAAmB;AAEf;IAAA,kBAA2B;IAA3B,yBAA2B;YAA3B,iBAA2B;IAC3B;AAD2B;;AAK3B;IAAA,cAA4D;IAA5D,YAA4D;IAA5D,eAA4D;IAA5D,gBAA4D;IAA5D;AAA4D;;AAI5D;IAAA,kBAAkC;IAAlC,cAAkC;IAAlC,qBAAkC;IAClC,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,UAAU;IACV;AANkC;;AAUlC;IAAA,kBAAoB;IAApB;AAAoB;;AAGxB;IACI,UAAU;AACd","sourcesContent":["/* Switch element */\n.form-switch {\n    @apply relative select-none;\n    width: 60px;\n}\n\n.form-switch label {\n    @apply block overflow-hidden cursor-pointer h-8 rounded-full;\n}\n\n.form-switch label>span:first-child {\n    @apply absolute block rounded-full;\n    width: 28px;\n    height: 28px;\n    top: 2px;\n    left: 2px;\n    right: 50%;\n    transition: all .15s ease-out;\n}\n\n.form-switch input[type=\"checkbox\"]:checked+label {\n    @apply bg-orange-600;\n}\n\n.form-switch input[type=\"checkbox\"]:checked+label>span:first-child {\n    left: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
