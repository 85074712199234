import { OpenInNew } from '@mui/icons-material';
import { Fragment } from 'react';
import {
  AppStoreButton,
  GooglePlayButton,
} from "react-mobile-app-button";

const IconStyle = {
  width: "max(30px, 3vw)",
  height: "max(30px, 3vw)",
}

export default function AppLauncher() {
    return (
        <Fragment>
            <div className="grid grid-cols-none grid-rows-3 md:grid-cols-3 md:grid-rows-none gap-3 mx-auto justify-center h-35 items-center w-fit">
                <AppStoreButton
                url={"https://apps.apple.com/kr/app/%EB%B9%84%EC%A6%88%ED%94%8C%EB%9E%AB-%EA%B5%AC%EA%B5%AC%ED%86%A1/id1576150335"}
                theme={"dark"}
                height={70}
                width={200}
                />
                <GooglePlayButton
                url={"https://play.google.com/store/apps/details?id=com.dinosband.gugutalk.client&pcampaignid=web_share"}
                theme={"dark"}
                height={70}
                width={200}
                />
                <div className="btn cursor-pointer h-full w-full p-0 m-0 border-black hover:bg-gray-200" onClick={() => window.open("https://webapp.99talk.app/")}>
                <div className="flex items-center gap-2">
                    <div className="p text-lg">또는 웹앱 열기</div><OpenInNew sx={IconStyle} />
                </div>
                </div>
            </div>
            <div className='md:flex gap-0 md:gap-1 w-fit justify-center items-center text-center mx-auto my-2'>
                <div className='p text-xs md:text-sm text-center md:text-end'>{`비즈플랫-구구톡 설치 → 회원가입 → 왼쪽 상단 메뉴`}</div>
                <div className='p text-xs md:text-sm text-center md:text-start'>{`→ SYNTHY 선택 → SYNTHY 가입`}</div>
            </div>
        </Fragment>
        )
}