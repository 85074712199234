import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import HeadphonesIcon from '@mui/icons-material/Headphones';
import CloseIcon from '@mui/icons-material/Close';
import { Spotify } from "react-spotify-embed";

import data from "./metadata.json";

const imgStyle = {
    maxHeight: "max(20vw, 150px)",
    width: "100%",
    objectFit: "cover",
}

export default function BlogContent() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ item, setItem ] = useState(data.filter((val) => val.$id === id)[0]);
    const [ showPlaylist, setShowPlaylist ] = useState(true);

    useEffect(() => {
        if ( !item ) {
            navigate('/blog');
        }
    }, [ item, navigate ])

    return (
        <Fragment>
            <div className="max-w-4xl mx-auto mt-10 md:mt-20 px-4">
            { item &&
                <div className="flex-col md:mr-20">
                    <img className="mb-8 object-cover" style={imgStyle} src={item.$thumbnail} alt={item.$thumbnail}/>
                    <p className="h4 md:h3 my-2">{item.$title}</p>
                    <p className="p text-sm md:text-md my-2">{item.$subtitle}</p>
                    <p className="p text-xs mt-6 mb-8">{item.$writer} | {item.$timestamp}</p>
                    <div className="md:fixed md:flex md:flex-col md:top-20 md:right-6 md:text-right">
                        <div className="hidden sm:block">
                        { showPlaylist ?
                            <CloseIcon style={{ width: "30px", height: "30px", cursor: "pointer" }}
                                onClick={() => setShowPlaylist(false)} /> :
                            <HeadphonesIcon style={{ width: "30px", height: "30px", cursor: "pointer" }}
                                onClick={() => setShowPlaylist(true)} /> }
                        </div>
                        <Spotify style={{ display: showPlaylist ? "inherit" : "none" }} link={item.spotifyUrl} />
                    </div>
                    <p className="p text-md my-20 whitespace-pre-wrap break-words leading-7" style={{wordBreak: "keep-all"}}>{item.$body}</p>
                </div>
            }
            </div>
        </Fragment>
    )
}