import Guitar from "../component/guitar.webp";
import ServiceItem from "../component/ServiceItem";

const services = [
    { title: "Guitar Lesson", description: "하고 싶은 사람", thumbImg: Guitar, price: "₩30,000" },
]

export default function Services() {
    return (
        <div className="grid grid-cols-4 gap-8">
            { services.map(( val, idx ) => (
                <ServiceItem key={idx} item={val} />
            ))}
        </div>
    )
}