import { Fragment, useState } from 'react';
import PopupImg from '../images/popup.png'
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

const imgStyle = {
    width: "max(50vw, 320px)"
};

export default function Popup() {
    const navigate = useNavigate();
    const [ show, setShow ] = useState(true);
    return (
        <Fragment>
        { show && 
            <div className='absolute top-0 right-0 w-full h-full bg-black/[.5] z-50' onClick={() => setShow(false)}>
                <div className="absolute z-50 left-6 md:translate-x-1/2 md:right-2/4 top-20">
                    <CancelIcon className="absolute left-3 top-3 cursor-pointer" onClick={() => setShow(false)}/>
                    <img className="cursor-pointer" style={imgStyle} src={PopupImg} onClick={() => navigate("/contest")} alt="Popup Image" />
                </div>
            </div>
        }
        </Fragment>
    )
}