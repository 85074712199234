import { Fragment } from 'react';

import Hero from './component/Hero';
import Features from './component/Features';
import Zigzag from './component/Zigzag';
import Newsletter from './component/Newsletter';

export default function Home() {
    return (
        <Fragment>
            <Hero />
            <Features />
            <Zigzag />
            <Newsletter />
        </Fragment>
    )
}