import './css/App.css';
import { Fragment, useCallback, useEffect } from 'react'
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';

import AOS from 'aos'
import 'aos/dist/aos.css'

import Home from './Home';
import Navbar from './component/NavBar';
import About from './component/pages/About';
import Footer from './component/Footer';
import Partners from './component/Partners';

import Blog from './component/pages/Blog';
import BlogContent from './component/pages/Blog/BlogContent';

import Portfolio from './component/pages/Portfolio';
import PersonalPortfolio from './component/pages/Portfolio/PersonalPortfolio';

function App() {

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
  })

  const withNavNFooter = useCallback(( component ) => (
    <Fragment>
      <div className='relative min-h-screen'>
        <Navbar />
        { component }
      </div>
      <Footer />
    </Fragment>
  ), []);

  return (
  <BrowserRouter>
			<Routes>
        <Route path='/' element={ withNavNFooter( <Home /> ) } />
        <Route path='/about' element={ withNavNFooter( <About /> ) } />
        <Route path='/partners' element={ withNavNFooter( <Partners /> ) } />
        <Route path='/blog' element={ withNavNFooter( <Blog /> ) } />
        <Route path='/blog/:id' element={ withNavNFooter( <BlogContent /> )} />
        {/* <Route path='/product' element={ withNavNFooter( <Product /> ) } /> */}
        <Route path='/portfolio' element={ withNavNFooter( <Portfolio /> ) } />
        <Route path='/portfolio/:id' element={ withNavNFooter( <PersonalPortfolio /> ) } />
        {/* <Route path='/pricing' element={ withNavNFooter( <p>pricing</p>) } /> */}
        {/* <Route path='/login' element={ withNavNFooter( <Login /> ) } /> */}
        <Route path='*' element={ <Navigate replace to ="/" /> } />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
